<template>
  <div class="camsContainer">
    <ContentHeader name="Gate" />

    <GateModal />

    <div class="currentView">
      <div>
        <div class="dataTableSelectorGroup">
          <div class="dataTableSelector">
            <SearchBar
              v-bind:search="search"
              v-bind:searchCallback="(data) => {this.performSearch(data)}"
            > </SearchBar>
            <svg
              class="filterIcon"
              width="32"
              height="26"
              viewBox="0 0 32 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="32" height="4" fill="#699991" rx="2" />
              <rect x="8" y="22" width="16" height="4" fill="#699991" rx="2" />
              <rect x="4" y="11" width="24" height="4" fill="#699991" rx="2" />
            </svg>
          </div>
        </div>
        <DataTable
          v-bind:tableData="tableData"
          v-bind:configuration="selectedItem"
          v-bind:openModalCallback="
            () => {
              handleModal(selectedItem);
            }
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import ContentHeader from "../ContentHeader";
import * as Constants from "../../js/constants";
import EventBus from "../../eventBus";
import DataTable from "./pageComps/DataTable.vue";
import SearchBar from "./pageComps/Search.vue";
import GateModal from "../modals/GateModal.vue";

export default {
  name: "GatePage",
  components: {
    ContentHeader,
    DataTable,
    GateModal,
    SearchBar
  },
  data() {
    return {
      showKeys: true,
      showIncidents: false,
      selectedItem: Constants.GATE,
      filterStartDate: null,
      filterEndDate: null,
      defaultStartDate: null,
      defaultEndDate: null,
      filters: [],
      tableData: [],
      search: "Gate Search",
      cardInfo: [
        {
          title: "First Time Keys Created",
          insight_id: "6e390e034cb7c6d64edb431a0e4519e8",
          subtitle_trend: "Good trend",
          sentiment: "positive",
          value: "11%",
          direction: "upward",
        },
        {
          title: "Keys Validated",
          insight_id: "5c779aa30a64c502cf060ef19d8f86a3",
          subtitle_trend: "Warning trend",
          sentiment: "warning",
          value: "3%",
          direction: "downward",
        },
        {
          title: "Average Transaction Time",
          insight_id: "5d9abc085056aac732b96d7fde09cb1a",
          subtitle_trend: "Good trend",
          sentiment: "positive",
          value: "3s",
          direction: "downward",
        },
        {
          title: "Resend Keys Created",
          insight_id: "2f1235b0f19dd2dddace7991d00088c6",
          subtitle_trend: "Great trend",
          sentiment: "positive",
          value: "80%",
          direction: "upward",
        },
        {
          title: "Party Keys Created",
          insight_id: "d1924d54a609019d25762fabd1b7e212",
          subtitle_trend: "Alert trend",
          sentiment: "alert",
          value: "30%",
          direction: "downward",
        },
        {
          title: "Total Keys Expired",
          insight_id: "ef67b32bdf70aaf6a4fb2adc80124288",
          subtitle_trend: "Alert trend",
          sentiment: "alert",
          value: "30%",
          direction: "upward",
        },
      ],
    };
  },
  created() {
    this.changeView(this.selectedItem);
    EventBus.$on("keys", this.changeView);
    EventBus.$on("incidents", this.changeView);

    var date = new Date();
    this.defaultEndDate = date.toISOString().split("T")[0];
    var startDate = new Date(date);
    startDate.setDate(date.getDate() - 7);
    //this.defaultStartDate = startDate.toISOString().split('T')[0]
    this.defaultStartDate = "2019-06-06";

    this.performSearch("Richard").then((apiData) => {
      console.log("Finished getting and setting API Data", apiData)
    });
  },
  methods: {
    openGateFiletersModal() {
      console.log("Opening Gate Filters Modal");
    },
    homeChangeText() {
      console.log("Home knows the button was clicked");
    },
    changeView(name) {
      console.log("Changing View from ", this.selectedItem, " to ", name);
      // if (name == "keys") {
      //   this.selectedItem = name;
      // } else if (name == "incidents") {
      //   this.selectedItem = name;
      // }
    },
    getDataFromAPI() {
      return new Promise((resolve, reject) => {
        fetch(
          `https://76pn72v42d.execute-api.us-east-2.amazonaws.com/staging-dev/nautica/keys/search?page=1&items_per_page=20&guest_name=&resident_name=&start_date=${
            this.filterStartDate ? this.filterStartDate : this.defaultStartDate
          }&end_date=${
            this.filterStartDate ? this.filterEndDate : this.defaultEndDate
          }&status`,
          {
            method: `GET`,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
          .then((res) => res.json())
          .then((data) => {
            console.log("Response Data", data);
            this.tableData = data;
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    performSearch(data) {
      this.search = data
      let url = `https://2r7ags60v2.execute-api.us-east-2.amazonaws.com/stage/nautica2/keys/search?resident_name=${data}&visitor_name=${data}&time_start=2022-01-02&time_end=2024-11-01&status=${data}&subdivision=${data}`
      return new Promise((resolve, reject) => {
        fetch(
          url,
          {
            method: `GET`,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
          .then((res) => res.json())
          .then((data) => {
            console.log("Search Data", data);
            this.tableData = data.data
            resolve(data.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    openGateModal() {
      this.$modal.show("gate-modal");
    },
    openUsersModal() {
      this.$modal.show("users-modal");
    },
    handleModal(configuration) {
      switch (configuration) {
        case Constants.GATE: {
          this.openGateModal();
          break;
        }
        case Constants.INCIDENTS: {
          console.log("Opening Incidents Modal");
          break;
        }
        case Constants.USERS: {
          this.openUsersModal();
          break;
        }
      }
    },
  },
};
</script>

<style scoped>

.dataTableSelectorGroup {
  display: flex;
  widows: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
}

.dataTableSelector:nth-child(3n) {
  margin-right: 0px;
}

.selectorContent {
  width: 100%;
  color: white;
  font-size: 28px;
}

.selectorContent::placeholder {
  width: 100%;
  color: white;
  font-size: 28px;
}

.filterIcon {
  margin-right: 16px;
}

input[type="text"]:focus {
  background-color: lightblue;
}

input[type="date"]:focus {
  outline: none;
  border: 0;
  appearance: none;
  border-width: 0px;
  border: none;
}
</style>