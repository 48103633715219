export const HOME = "Home"
export const GATE = "Gate"
export const ENTRY_LOG = "Entry Log"
export const KEYS = "Keys"
export const FREQUENT_VISITORS = "Frequent Visitors"
export const BLOCKED_LIST = "Blocked List"
export const VENDORS = "Vendors"
export const INCIDENTS = "Incidents"
export const ANALYTICS = "Reporting"
export const USERS= "Users"
export const RESIDENT_DIRECTORY= "Resident Directory"
export const VEHICLES= "Vehicles"
export const PETS= "Pets"
export const SUPPORT = "Support"
export const NAVIGATION_ITEM_SELECTED = "navigationItemSelected";
export const ACTION_INVALID = "This action is invalid!"