<template>
  <div class="activeContent">
    <div v-if="currentActiveContent == home">
      <Home />
    </div>
    <div v-else-if="currentActiveContent == gate">
      <Gate />
    </div>
    <div v-else-if="currentActiveContent == incidents">
      <Incidents />
    </div>
    <div v-else-if="currentActiveContent == analytics">
      <Analytics />
    </div>
    <div v-else-if="currentActiveContent == users">
      <Users />
    </div>
  </div>
</template>

<script>
import Home from "./pages/Home";
import Gate from "./pages/Gate.vue";
import EventBus from "../eventBus";
import * as Constants from "../js/constants";
import Analytics from "./pages/Analytics.vue";
import Incidents from "./pages/Incidents.vue";
import Users from "./pages/Users.vue"

export default {
  name: "ActiveContent",
  components: {
    Home,
    Gate,
    Analytics,
    Incidents,
    Users
  },
  data() {
    return {
      currentActiveContent: Constants.HOME,
      home: Constants.HOME,
      gate: Constants.GATE,
      incidents: Constants.INCIDENTS,
      analytics: Constants.ANALYTICS,
      users: Constants.USERS,
      support: Constants.SUPPORT,
    };
  },
  created() {
    var eventTitle = Constants.NAVIGATION_ITEM_SELECTED;
    EventBus.$on(eventTitle, (data) => {
      this.showContent(data);
    });
    const path = window.location.pathname;
    console.log("Active Content.vue querystring", path);
    const filteredPath = path.replace('/','')
    this.showContent(`${filteredPath}`)
  },
  methods: {
    showContent(data) {
      console.log("Show Content:", data);
      window.scrollTo(0,0)
      let pathToNavigate
      if(data.includes('/')) {
        pathToNavigate = data.substring(0, data.indexOf('/'))
      } else {
        pathToNavigate = data
      } 
      console.log("pathToNavigate", data)
      console.log("pathToNavigate", pathToNavigate)
      switch (pathToNavigate) {
        case Constants.HOME: {
          this.showHome();
          break;
        }
        case Constants.GATE: {
          this.showGate();
          break;
        }
        case Constants.INCIDENTS: {
          this.showIncidents();
          break;
        }
        case Constants.ANALYTICS: {
          this.showAnalytics();
          break;
        }
        case Constants.USERS: {
          this.showUsers();
          break;
        }
        case Constants.SUPPORT: {
          this.showSupport();
          break;
        }
      }
    },
    showHome() {
      console.log("Active Content", "Show Home");
      this.currentActiveContent = Constants.HOME;
    },
    showGate() {
      console.log("Active Content", "Show Gate");
      this.currentActiveContent = Constants.GATE;
    },
    showIncidents() {
      console.log("Active Content", "Show Incidents");
      this.currentActiveContent = Constants.INCIDENTS;
    },
    showAnalytics() {
      console.log("Active Content", "Show Analytics");
      this.currentActiveContent = Constants.ANALYTICS;
    },
    showUsers() {
      console.log("Active Content", "Show Users");
      this.currentActiveContent = Constants.USERS;
    },
    showSupport() {
      console.log("Active Content", "Show Users");
      let url = "https://communitytech-support.atlassian.net/servicedesk/customer/portals"
      window.open(url, '_blank').focus();
    },
  },
};
</script>

<style scoped>
.activeContent {
  width: 100%;
  height: 100%;
  padding-left: 32px;
  padding-right: 32px;
  background-color: #f2f2f2;
  display: block;
}
</style>