<template>
  <div class="header-container">
      <h2 class="header-title">{{ name }}</h2>
  </div>
</template>

<script>
import EventBus from "../eventBus.js";
export default {
  name: "ContentHeader",
  props: ["name"],
  // props: {
  //   videoId: {
  //     type: String,
  //     required: true,
  //   },
  // },
  data() {
    return {
      id: 1,
      isActive: true,
      title: "Home", // this will eventually be dynamic and be based off the page we are currently on...will probably get kicked off by EventBus
    };
  },
  components: {},
  methods: {
    emitMethod(selector) {
      EventBus.$emit(selector, selector);
      if (selector == "keys") {
        this.isActive = !this.isActive;
      } else {
        this.isActive = !this.isActive;
      }
    },
  },
  created() {},
};
</script>

<style scoped>
  .header-container {
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
  }
  .header-title {
    color: #000000;
    font-size: 64px;
    padding: 0px;
    margin-top:16px;
    margin-bottom: 16px;
  }
  
</style>