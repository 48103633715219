import { render, staticRenderFns } from "./App.vue?vue&type=template&id=3ec73140&scoped=true"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"
import style0 from "./css/gatewatch-styles.css?vue&type=style&index=0&id=3ec73140&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ec73140",
  null
  
)

export default component.exports