
<template>
  <div class="graphContainer">
    <div class="graphConfigs">
      <h1 v-if="title">{{ title }}</h1>
      <!-- TODO: Replace Select with Custom ul li implementation to match style guidelines -->
      <select v-if="title"
        name="reports"
        v-on:change="onChange($event)"
        v-model="reportIndex"
      >
        <option
          v-for="(option, index) in reportOptions[$props.configuration]"
          v-bind:value="index"
          v-bind:key="option.report_display_name"
        >
          {{ option.report_display_name }}
        </option>
      </select>
    </div>
    <div class="container">
      <h1 class="graphReportTitle">{{ selectedReport.report_display_name }}</h1>
      <bar-chart v-bind:options="chartOptions" :chart-data="chartData" />
    </div>
  </div>
</template>

<script>
import BarChart from "../../../js/barChart";
import EventBus from "../../../eventBus.js";
import * as Constants from "../../../js/constants";

const data = {
  a: 1,
  reportIndex: 0,
  selectedReport: {
    report_id: "",
    report_display_name: "",
  },
  //old url: https://76pn72v42d.execute-api.us-east-2.amazonaws.com/staging-dev/nautica/keys/analytics/search?year=2019&time_span=Months&report=----&month=&day="
  dataEndpoint:"https://76pn72v42d.execute-api.us-east-2.amazonaws.com/staging-dev/nautica/keys/analytics/search?year=2019&time_span=Months&report=----&month=&day=",
  reportOptions: {
    Gate: [
      {
        report_id: "home_gate_summary_graph_12_hour",
        report_display_name: "Keys expected in the Next 12 Hours",
      },
      {
        report_id: "home_gate_summary_graph_keys_past_week",
        report_display_name: "Keys in the past week",
      },
      {
        report_id: "home_gate_summary_graph_keys_validated_past_week",
        report_display_name: "Keys Validated in the past Week",
      },
    ],
    Incidents: [
      {
        report_id: "home_incident_summary_graph_12_hour",
        report_display_name: "Incidents created in past 12 Hours",
      },
      {
        report_id: "home_incident_summary_graph_incidents_created_past_week",
        report_display_name: "Incidents created in past week",
      },
      {
        report_id: "home_incident_summary_graph_incidents_closed_past_week",
        report_display_name: "Incidents closed in past week",
      },
    ],
  },
  chartOptions: {
    responsive: true,
    maintainAspectRatio: false,
    hoverBorderWidth: 20,
    borderRadius: 4,
    fontColor: "#fff",
    legend: {
      display: true,
      labels: {
        fontColor: "rgb(255, 255, 255)",
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontColor: "#ffffff",
            max: 100,
          },
          fontColor: "#fff",
          gridLines: { zeroLineColor: "#ffffff" },
        },
      ],
      xAxes: [
        {
          ticks: {
            beginAtZero: false,
            fontColor: "#ffffff",
          },
          fontColor: "#fff",
          gridLines: { zeroLineColor: "#ffffff" },
        },
      ],
    },
  },
  chartData: {
    hoverBackgroundColor: "red",
    hoverBorderWidth: 10,

    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        fontColor: "#fff",
        label: "Incidents Created",
        backgroundColor: "#ffffffc3",
        borderColor: "#ffffffff",
        borderWidth: 2,
        data: [40, 20, 12, 39, 10, 80, 39, 40, 40, 20, 12, 11],
      },
    ],
  },
  closedData: {
    labels: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
    datasets: [
      {
        fontColor: "#fff",
        label: "Incidents Closed",
        backgroundColor: "#ffffffc3",
        borderColor: "#ffffffff",
        borderWidth: 2,
        data: [5, 73, 56, 7, 97, 24, 42, 55],
      },
    ],
  },
  twelveHourData: {
    labels: [
      "10 AM",
      "11 AM",
      "12 PM",
      "1 PM",
      "2 PM",
      "3 PM",
      "4 PM",
      "5 PM",
      "6 PM",
      "7 PM",
      "8 PM",
      "9 PM",
      "10 PM",
    ],
    datasets: [
      {
        fontColor: "#fff",
        label: "Incidents Created",
        backgroundColor: "#ffffffc3",
        borderColor: "#ffffffff",
        borderWidth: 2,
        data: [40, 31,84,69,22,8,20,9,77,19,82, 50, 11],
      },
    ],
  },
  weekChartData: {
    labels: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
    datasets: [
      {
        fontColor: "#fff",
        label: "Incidents Created",
        backgroundColor: "#ffffffc3",
        borderColor: "#ffffffff",
        borderWidth: 2,
        data: [40, 20, 12, 39, 10, 80, 91, 56],
      },
    ],
  },
};



export default {
  name: "AnalyticsGraph",
  components: {
    BarChart,
  },
  props: ["configuration", "title", "labels", "dataset"],
  data() {
    return data;
  },
  created() {
    EventBus.$on(Constants.GATE, this.handleConfiguration);
    EventBus.$on(Constants.INCIDENTS, this.handleConfiguration);
    this.handleConfiguration(this.$props.configuration);
  },
  mounted() {
    this.handleConfiguration(this.$props.configuration);
  },
  updated() {
    this.handleConfiguration(this.$props.configuration);
    this.tempData = this.chartData;
  },
  beforeUpdate() {
    console.log(
      "Analytics Graph Before Update selected Report",
      this.selectedReport
    );
  },
  methods: {
    retrieveQueryData(query) {
      console.log("Retrieving Data for", this.title, query);
      if (
        query == "home_incident_summary_graph_incidents_created_past_week" ||
        query == "home_gate_summary_graph_keys_past_week"
      ) {
        this.chartData = this.weekChartData;
        //this.chartData = this.dataset;
      }
      else if (query == "home_incident_summary_graph_12_hour" ||
        query == "home_gate_summary_graph_12_hour"){
          this.chartData = this.twelveHourData
      } 
      else if (query == "home_incident_summary_graph_incidents_closed_past_week" ||
        query == "home_gate_summary_graph_keys_validated_past_week"){
          this.chartData = this.closedData
      } else {
        this.chartData = this.tempData;
      }

      /*return new Promise((resolve, reject) => {
            fetch(this.dataEndpoint.replace("----", query),
                {
                    method: `GET`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                }).then(res => res.json())
                .then(data => {
                    console.log(query, data)
                    resolve(true)
                }).catch(err => {
                    reject(err)
                })
        });*/
    },
    onChange(event) {
      console.log("this report is value", event.target.value);
      console.log("this report is", this.selectedReport);
      this.retrieveQueryData(event.target.value);
    },
    handleConfiguration(name) {
      if (name == Constants.GATE) {
        console.log("Configuring for Keys");
      } else if (name == Constants.INCIDENTS) {
        console.log("Configuring for incidents");
      }
      console.log(
        "Analytics Graph Configuration Prop",
        this.$props.configuration,
        this.selectedReport
      );
      this.selectedReport = this.reportOptions[this.$props.configuration][
        this.reportIndex
      ];

      this.retrieveQueryData(this.selectedReport.report_id);
    },
  },
};
</script>

<style>
.graphContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  margin-bottom: 16px;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  background-color: #699991;
}

.graphReportTitle {
  color: white;
  margin: 0px;
  display: block;
  text-align: left;
}

.graphConfigs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.graphConfigs h1 {
  color: #87bab7;
  text-align: left;
  font-size: 48px;
}

.graphConfigs select {
  background-color: #87bab7;
  text-align: left;
  border: 0px solid white;
  border-radius: 4px;
  height: 48px;
  color: white;
  font-size: 24px;
  padding: 8px;
}

.graphConfigs select option {
  background-color: #87bab7;
  text-align: center;
  border: 0px solid white;
  border-radius: 4px;
  height: 48px;
  width: 400px;
  color: white;
  font-size: 24px;
  margin: 16px;
  float: left;
}
</style>