<template>
  <div class="camsContainer">
    <ContentHeader name="Incident Management" />

    <div class="currentView">
      <!-- These selectors should probably be their own component -->

      <div>
        <div class="dataTableSelectorGroup">
          <div
            class="dataTableSelector"
            id="filtersModal"
          >
            <SearchBar
            v-bind:search="search"
            v-bind:searchCallback="(data) => {this.performSearch(data)}"></SearchBar>
            <div class="iconGroup" @click="openGateFiletersModal">
              <svg
                class="filterIcon"
                width="32"
                height="26"
                viewBox="0 0 32 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="4" fill="#699991" rx="2"/>
                <rect x="8" y="22" width="16" height="4" fill="#699991" rx="2"/>
                <rect x="4" y="11" width="24" height="4" fill="#699991" rx="2"/>
              </svg>
            </div>
          </div>
        </div>
        <DataTable
          v-bind:tableData="usersTableData"
          v-bind:configuration="incidents"
          v-bind:search="search"
          v-bind:openModalCallback="
            () => {
              handleModal(users);
            }
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import ContentHeader from "../ContentHeader.vue";
import * as Constants from "../../js/constants";
import EventBus from "../../eventBus";
import DataTable from "./pageComps/DataTable.vue";
import SearchBar from "./pageComps/Search.vue";


export default {
  name: "IncidentsPage",
  components: {
    ContentHeader,
    DataTable,
    SearchBar,
  },
  data() {
    return {
      showKeys: true,
      showIncidents: false,
      selectedItem: Constants.INCIDENTS,
      filterStartDate: null,
      filterEndDate: null,
      defaultStartDate: null,
      defaultEndDate: null,
      filters: [],
      usersTableData: [],
      tableData: [],
      search: "",
      users: Constants.USERS,
      incidents: Constants.INCIDENTS,
    };
  },
  created() {
    this.changeView(this.selectedItem);
    EventBus.$on("keys", this.changeView);
    EventBus.$on("incidents", this.changeView);

    var date = new Date();
    this.defaultEndDate = date.toISOString().split("T")[0];
    var startDate = new Date(date);
    startDate.setDate(date.getDate() - 7);
    //this.defaultStartDate = startDate.toISOString().split('T')[0]
    this.defaultStartDate = "2019-06-06";

    this.performSearch().then((apiData) => {
      console.log("Finished getting and setting API Data", apiData);
    });
  },
  methods: {
    openGateFiletersModal() {
      console.log("Opening Gate Filters Modal");
    },
    homeChangeText() {
      console.log("Home knows the button was clicked");
    },
    changeView(name) {
      console.log("Changing View from ", this.selectedItem, " to ", name);
      if (name == "keys") {
        this.showKeys = true;
        this.showIncidents = false;
        this.selectedItem = name;
      } else if (name == "incidents") {
        this.showIncidents = true;
        this.showKeys = false;
        this.selectedItem = name;
      }
    },
    getIncidentsData() {
      return new Promise((resolve, reject) => {
        fetch(
          "https://76pn72v42d.execute-api.us-east-2.amazonaws.com/staging-dev/nautica/incidents/home/",
          {
            method: `GET`,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
          .then((res) => res.json())
          .then((data) => {
            console.log("Response Data", data);
            this.usersTableData = data;
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getDataFromAPI() {
      return this.getIncidentsData();
    },
    performSearch(data) {
      this.search = data
      let url = `https://2r7ags60v2.execute-api.us-east-2.amazonaws.com/stage/nautica2/incidents`
      return new Promise((resolve, reject) => {
        fetch(
          url,
          {
            method: `GET`,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
          .then((res) => res.json())
          .then((data) => {
            console.log("Search Data", data);
            this.usersTableData = data.data
            resolve(data.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    openGateModal() {
      this.$modal.show("gate-modal");
    },
    openUsersModal() {
      this.$modal.show("users-modal");
    },
    handleModal(configuration) {
      switch (configuration) {
        case Constants.GATE: {
          this.openGateModal();
          break;
        }
        case Constants.INCIDENTS: {
          console.log("Opening Incidents Modal");
          break;
        }
        case Constants.USERS: {
          console.log("Opening Users Modal");
        }
      }
    },
  },
};
</script>

<style scoped>

.dataTableSelectorGroup {
  display: flex;
  widows: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
}

.dataTableSelector:nth-child(3n) {
  margin-right: 0px;
}

.selectorContent {
  width: 100%;
  color: white;
  font-size: 28px;
  display: inline;
  margin-right: 16px;
}

.selectorContent::placeholder {
  width: 100%;
  color: white;
  font-size: 28px;
}

.filterIcon {
  display: inline;
}

.iconGroup {
  display: inline;
}
</style>