<template>
  <div class="contentPane">
    <ActiveContent />
  </div>
</template>

<script>
import ActiveContent from "./ActiveContent.vue";

export default {
  name: "Content-Pane",
  components: {
    ActiveContent,
  },
};
</script>

<style scoped>
.contentPane {
  width: calc(100% - 200px);
  margin: 0px;
  display: block;
  min-height: 88%;
  max-height: 90%;
  float: right;
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: auto;
}
</style>