<template>
  <div class="search-container">
    <input type="text" placeholder="Search" class="selectorContent" v-model="localSearch" />
  </div>


</template>


<script>


export default {
  name: "SearchBar",
  props: ["search", "searchCallback"],
  watch: {
    localSearch(newVal, oldVal) {
      let newSearchTime = new Date().getTime();
      if (newVal !== oldVal && newVal.length > 2 && newSearchTime - this.searchLastUpdated > 1500) {
        console.log("Last Search", this.searchLastUpdated);
        this.searchLastUpdated = newSearchTime;
        this.$props.searchCallback(newVal); // Call the parent's callback with the new value
      }
    },
    search(newVal) {
      // Sync the prop value to the local data when the parent changes the prop
      this.localSearch = newVal;
    },
  },
  data() {
    return {
      localSearch: this.search, // Initialize the localSearch with the prop value
      searchLastUpdated: new Date().getTime()
    }
  }


}

</script>


<style>
.selectorContent {
  width: 100%;
  font-size: 20px;
  font-weight: 550;
  border: 0;
  outline: 0;
}

.search-container {
  width: 100%;
  text-align: left;
}

::placeholder {
  color: black;
  opacity: 1;
  /* Firefox */
  font-weight: 550;
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: black;
  font-weight: 550;
}
</style>