// CommitChart.js
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Bar,
  props: ['options'],
  mixins: [reactiveProp],
  mounted() {
    console.log("Bar Chart props", this.chartData, this.options)
    // Overwriting base render method with actual data.
    /*this.renderChart({
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      datasets: [
        {
          label: 'GitHub Commits',
          backgroundColor: '#ffffff',
          data: [40, 20, 12, 39, 10, 40, 39, 40, 40, 20, 12, 11]
        }
      ],

    }, {
      responsive: true, maintainAspectRatio: false, hoverBorderWidth: 20, borderRadius: 4
    })*/
    this.renderChart(this.chartData, this.options)
  }
}