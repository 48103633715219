<template>
  <div class="graphContainer">
    <div class="graphConfigs">
      <h1>{{ title }}</h1>
    </div>
    <div class="chartContainer">
      <v-data-table
        dense
        :headers="finalHeaders"
        :items="keyData"
        :item-class="() => 'dataItem'"
        class="elevation-1 table"
        loading
        :search="search"
        loading-text="Loading... Please wait"
        @click:row="openModalCallback"
      >
        <!-- <template v-slot:item.current_status="{ item }">
          <v-chip color="red">
            {{ item.calories }}
          </v-chip>
        </template> -->
        <!-- <template v-slot:top>
          <v-text-field
            v-model="localSearch"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mx-4"
          ></v-text-field>
        </template> -->
      </v-data-table>
    </div>
  </div>
</template>
<script>
import * as Constants from "../../../js/constants";

export default {
  props: ["configuration", "title", "tableData", "openModalCallback", "search"],
  watch: {
    tableData: function (newVal, oldVal) {
      //must now check to see whats being passed to determin what headers to use
      switch (this.configuration) {
        case Constants.GATE: {
          this.finalHeaders = this.keyHeaders;
          break;
        }

        case Constants.INCIDENTS: {
          this.finalHeaders = this.incidentHeaders;
          break;
        }

        case Constants.USERS: {
          console.log("users!");
          this.finalHeaders = this.usersHeaders;
          break;
        }
      }

      // watch it
      this.keyData = this.processInputData(this.tableData);
      console.log("tableData changed: ", newVal, " | was: ", oldVal);
    },
  },
  methods: {
    processInputData(inputData) {
      try {
        return inputData.map((item) => {
          if (this.configuration == Constants.USERS) {
            return {
              address: item.address,
              email: item['primary_email_address'],
              name: `${item.first_name} ${item.last_name}`,
              phone: item['primary_phone_number'],
              role: item.role,
              subdivision: item.subdivision,
            };
          } else if (this.configuration == Constants.GATE) {
            return {
              key_id: item.key_id,
              visitor_name: `${item.visitor_first_name} ${item.visitor_last_name}`,
              resident_name: `${item.user_first_name} ${item.user_last_name}`,
              time_active: item.time_active ? `${this.formattedDate(item.time_active)} ${this.formattedTime(item.time_active)}` : `${this.formattedDate(item.time_created)} ${this.formattedTime(item.time_created)}`,
              time_validated: item.time_validated ? `${this.formattedDate(item.time_active)} ${this.formattedTime(item.time_active)}` : `${this.formattedDate(item.time_created)} ${this.formattedTime(item.time_created)}`,
              expiration: item.expiration_time,
              party_name: item.party_id,
            };
          } else if (this.configuration == Constants.INCIDENTS) {
            let returnValue = {
              incident_id: item.incident_id,
              title: item.title,
              incident_type: item.incident_type,
              reporter: item.reporter,
              time_created: `${this.formattedDate(item.time_created)} ${this.formattedTime(item.time_created)}`,
              current_status: item.current_status,
            }
            console.log("Returning Incidents Item", returnValue)
            return returnValue

          }

          // name email phone address subdivision role
        });
      } catch (e) {
        console.log("Error", e);
      }
    },

    formattedDate(dateString) {
      let date = new Date(dateString);
      let today = new Date();
      var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      var day;
      var day_value = date.getDate();
      let month = date.getMonth() + 1;
      if (day_value < 10) {
        day_value = "0" + day_value;
      }
      if (month < 10) {
        month = "0" + month;
      }

      var formattedDate;

      if (
        date.getMonth() == today.getMonth() &&
        date.getDate() == today.getDate()
      ) {
        day = "Today";
        formattedDate = day + ", ";
      } else {
        day = days[date.getDay()];
        formattedDate = date.toISOString().split('T')[0]
          //day + ", " + month + "/" + day_value + "/" + (date.getYear() - 100);
           //month + "-" + day_value + "-" + (date.getYear() - 100);
      }

      return formattedDate;
    },
    formattedTime(dateString) {
      let date = new Date(dateString);
      // var hours = date.getHours();
      // var minutes = date.getMinutes();
      // var ampm = hours >= 12 ? "PM" : "AM";
      // hours = hours % 12;
      // hours = hours ? hours : 12; // the hour '0' should be '12'
      // if (hours < 10) {
      //   hours = "0" + hours;
      // }
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      //var strTime = hours + ":" + minutes + " " + ampm;
      var strTime = date.toISOString().split('T')[1]
      //return strTime;
      //console.log(strTime)
      return strTime.substr(0, strTime.indexOf("."))
    },
  },
  mounted() {
    console.log("tableData to be processed", this.tableData);
  },
  updated() {
    console.log("keyData updated", this.keyData);
  },
  data() {
    return {
      finalHeaders: [],
      keyHeaders: [
        { text: "Key ID",align: "start",sortable: false,value: "key_id",},
        { text: "Visitor Name", value: "visitor_name", width: '2em'},
        { text: "Resident Name", value: "resident_name" , },
        { text: "Time Active", value: "time_active" },
        { text: "Expiration", value: "expiration" },
        { text: "Time Validated", value: "time_validated" },
        { text: "Party Name", value: "party_name" },
        
        { text: "Time Active1", value: "time_active" },
        { text: "Expiration1", value: "expiration" },
        { text: "Time Validated1", value: "time_validated" },
        { text: "Party Name1", value: "party_name" },
        { text: "Time Active2", value: "time_active" },
        { text: "Expiration2", value: "expiration" },
        { text: "Time Validated2", value: "time_validated" },
        { text: "Party Name2", value: "party_name" },
      ],
      incidentHeaders: [
        {
          text: "Incident ID",
          align: "start",
          sortable: true,
          value: "incident_id",
        },
        { text: "Title", value: "title" },
        { text: "Category", value: "incident_type", filterable: false },
        { text: "Current Assignee", value: "reporter", filterable: false },
        { text: "Time Created", value: "time_created", filterable: false },
        { text: "Current Status", value: "current_status", filterable: false },
      ],
      usersHeaders: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Address", value: "address" },
        { text: "Subdivision", value: "subdivision" },
        { text: "Role", value: "role" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Address", value: "address" },
        { text: "Subdivision", value: "subdivision" },
        { text: "Role", value: "role" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Address", value: "address" },
        { text: "Subdivision", value: "subdivision" },
        { text: "Role", value: "role" },
      ],
      keyData: [],
      localSearch: "",
    };
  },
};
</script>
<style>
.graphContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  margin-bottom: 16px;
}

.chartContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  overflow-x: scroll;
  /* background-color: #699991; */
}

.graphReportTitle {
  color: white;
  margin: 0px;
  display: block;
  text-align: left;
}

.graphConfigs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.graphConfigs h1 {
  color: #87bab7;
  text-align: left;
  font-size: 48px;
}

.graphConfigs select {
  background-color: #87bab7;
  text-align: left;
  border: 0px solid white;
  border-radius: 0px;
  height: 48px;
  color: white;
  font-size: 24px;
  padding: 8px;
}

.graphConfigs select option {
  background-color: #87bab7;
  text-align: center;
  border: 0px solid white;
  border-radius: 0px;
  height: 48px;
  width: 400px;
  color: white;
  font-size: 24px;
  margin: 16px;
  float: left;
}

.table {
  background-color: #87bab7;
  overflow-y: scroll;
}


.v-data-table__wrapper {
  background-color: #D9D9D9;
  color: white;
  border-radius: 0px;
}

.v-data-table__wrapper table tbody tr:hover td {
  color: black; 
  background-color: #69999140;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 3px;
  transition: 0.5s;
}

.v-data-table--dense  .v-data-table__wrapper  table  tbody  tr  td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.v-data-table--dense  .v-data-table__wrapper  table  tbody  tr {
  border-radius: 25px;
}

.v-data-table-header tr th span {
  background-color: #D9D9D9;
  color: black;
  font-weight: 600;
}

.v-data-table-header__icon tr th i {
  color: white;
}

.v-data-table
  elevation-1
  table
  v-data-table--has-top
  v-data-table--has-bottom
  theme--light {
  border-radius: 4px;
  width: 99%;
}

.dataItem {
  background-color: white;
  color: black;  
  text-align: left;
}

.text-start {
  color: black;
  min-width: 10em;
  text-align: left;
}

</style>

// {
//     "key_id": "953bc61bcfa3",
//     "user_uid": "38bf5676-eee0-4103-81a7-f812bb21d14e",
//     "visitor_name": "Henry Metral",
//     "visitor_number": "8507586114",
//     "link_url": "https://api.qrserver.com/v1/create-qr-code/?data=953bc61bcfa3&size=500x500",
//     "current_status": "VALIDATED",
//     "expiration": "2020-10-02T03:03:00.000Z",
//     "validator_uid": "community_callbox_scanner",
//     "time_active": "2020-10-02T00:03:00.000Z",
//     "date_active": null,
//     "time_created": "2020-10-02T00:43:54.000Z",
//     "time_key_sent": "2020-10-02T00:43:54.000Z",
//     "time_validated": "2020-10-02T00:44:28.000Z",
//     "time_expired": null,
//     "expired": 0,
//     "party_id": null,
//     "party_key": 0,
//     "party_name": null,
//     "uid": "38bf5676-eee0-4103-81a7-f812bb21d14e",
//     "first_name": "Richard",
//     "last_name": "Dale",
//     "email": "rmd127@miami.edu",
//     "phone": "9548052619",
//     "address": "4143 43rd St, Sunnyside, NY 11104",
//     "subdivision": "Fantasy Isle",
//     "role": "resident",
//     "notification_token": "ExponentPushToken[0k09k-Gs2cjrUr16ZjrZ02]"
// }

