<template>
  <div class="header">
    <img id="logo" alt="Community logo" src="../../assets/logohb.png" />
    <div id="userDetails">
      <button class="header-action-button" id="create-visitor" @click="openCreateVisitorDialog">Create Visitor</button>
      <button class="header-action-button" id="create-incident" @click="openCreateIncidentDialog">Create Incident</button>
      <div class="dropdown">
        <button class="dropbtn" >
          <p id="propertySelector">{{ currentProperty }}</p>
          <i class="fa fa-caret-down" style="font-size:48px;color:black"></i>
        </button>
        <div class="dropdown-content">
          <a href="#" @click="setPropertyId">the_florence_1</a>
          <a href="#" @click="setPropertyId">the_florence_2</a>
          <a href="#" @click="setPropertyId">the_florence_3</a>
        </div>
      </div>

      <div class="dropdown">
        <button class="dropbtn">
          <img id="user-icon" alt="User Icon" src="../../assets/Icons/CTicons-04.png" />
        </button>
        <div class="dropdown-content">
          <div v-if="showCustomDropdownLayout" id="userDropdownContent"> {{ userName }}</div>
          <a href="#">My Account</a>
          <a href="#">Log Out</a>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "CTSHeader",
  props: ["userData"],
  data() {
    return {
      userId: "",
      userName: "",
      userEmail: "",
      propertyList: [],
      currentProperty: "",
      group: "",
      showCustomDropdownLayout: false,
    };
  },
  watch: {
    userData: function (newVal, oldVal) {
      console.log("userData changed: ", newVal, " | was: ", oldVal);
      this.userId = newVal.sub;
      this.userName = `${newVal.name} ${newVal.family_name}`;
      this.userEmail = newVal.email;
      this.currentProperty = newVal["custom:cts_property_id"];
      this.group = newVal["custom:cts_group_id"];
    },
  },
  methods: {
    setPropertyId(event) {
      console.log("Set Property Id", event.target);
      this.currentProperty = event.target.innerText;
    },
    openCreateVisitorDialog() {
      alert("Opening Create Visitor Dialog")
    },
    openCreateIncidentDialog() {
      alert("Opening Create Incident Dialog")

    }
  },
  created() {
    this.currentProperty = "the_florence_1"
  }
};
</script>

<style scoped>
.header {
  background: #fff;
  box-shadow: 0px 4px 4px 0px #699991;
  widows: 100%;
  height: 100px;
  position: sticky;
  top: 0px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#logo {
  padding: 10px 0px 10px 35px;
  height: 100%;
}

#userDetails {
  display: flex;
  margin-right: 22px;
  align-items: center;
}

.header-action-button {
  width: 132px;
  height: 41px;
  margin-left: 8px;
  margin-right: 8px;
  flex-shrink: 0;
}

#create-visitor {
  border-radius: 7px;
  border: 1px solid #000;
  background: rgba(135, 186, 183, 0.27);
}

#create-incident {
  border-radius: 7px;
  border: 1px solid #000;
  background: rgba(255, 219, 219, 0.39);
}


#user-icon {
  height: 58px;
  width: 58px;
}


#propertySelector {
  display: inline;
  margin-right: 8px;
  padding: 8px;
  color: #000000;
  position: relative;
  top: -12px
}

#propertySelector:hover {
  background: #d3d3d3;
  color: #000000;
  border-radius: 8px;
}

#userDropdown {
  display: inline;
  padding: 8px;
}

#userDropdown:hover {
  background: white;
  color: #87bab7;
  border-radius: 8px;
}

.dropbtn {
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}


#userDropdownContent {
  height: 300px;
  min-width: 300px;
}
</style>