<template>
  <div class="camsContainer">
    <ContentHeader name="Home" />
    <!-- you can also declare them like normal tags -->

    <!-- testing for modal
    <GateModal /> -->
    <button @click="openGateModal()"> OPEN Gate Modal </button>
    <br>

    <!-- testing for modal -->
    <button @click="openUsersModal()">OPEN Users Modal</button>
    <UsersModal />
    <GateModal />

    <!-- currently takes alerts which is a singular json, will need to adapt to mulitple -->

    <div class="currentView">
      <!-- These selectors should probably be their own component -->

      <AnalyticsGraph title="At a Glance" v-bind:configuration="selectedItem" />

      <DataTable
        title="Data"
        v-bind:tableData="tableData"
        v-bind:configuration="gate"
        v-bind:openModalCallback="(inputData) => { handleModal(selectedItem, inputData); }"
      />
    </div>
  </div>

  <!-- actual content -->
  <!-- Should have its own, rendered conten is conditional of selectors -->
  <!-- Data in home dicatates some of the data for HomeActiveContent, data wil be held in this comp -->
  <!-- This will dicate exactly what is rendered -->
</template>

<script>
import ContentHeader from "../ContentHeader";
import AnalyticsGraph from "./pageComps/AnalyticsGraph";
import EventBus from "../../eventBus";
import UsersModal from "../modals/UsersModal";
import GateModal from "../modals/GateModal";
import * as Constants from "../../js/constants";
import DataTable from "./pageComps/DataTable";

export default {
  name: "Home-Page",
  components: {
    ContentHeader,
    AnalyticsGraph,
    UsersModal,
    GateModal,
    DataTable,
  },
  created() {
    this.changeView(this.selectedItem);
    EventBus.$on("keys", this.changeView);
    EventBus.$on("incidents", this.changeView);
    this.getDataFromAPI().then((apiData) => {
      console.log("Finished getting and setting API Data", apiData);
    });
    this.setIncidentsData().then((apiData) => {
      console.log("Finished getting and setting API Data", apiData);
    });

    this.getUsersData().then((apiData) => {
      console.log("Finished getting and setting Users table Data", apiData);
    });
  },
  methods: {
    homeChangeText() {
      console.log("Home knows the button was clicked");
    },
    changeView(name) {
      console.log("Changing View from ", this.selectedItem, " to ", name);
      if (name == "keys") {
        this.showKeys = true;
        this.showIncidents = false;
        this.selectedItem = Constants.GATE;
      } else if (name == "incidents") {
        this.showIncidents = true;
        this.showKeys = false;
        this.selectedItem = Constants.INCIDENTS;
      }
    },
    getDataFromAPI() {
      return new Promise((resolve, reject) => {
        fetch(
          "https://76pn72v42d.execute-api.us-east-2.amazonaws.com/staging-dev/nautica/keys/search?page=1&items_per_page=20&guest_name=&resident_name=Jason&start_date=2019-04-27&end_date=2021-04-27&status",
          {
            method: `GET`,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
          .then((res) => res.json())
          .then((data) => {
            console.log("Response Data", data);
            this.tableData = data;
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getUsersData() {
      return new Promise((resolve, reject) => {
        fetch(
          "https://76pn72v42d.execute-api.us-east-2.amazonaws.com/staging-dev/nautica/residents/all",
          {
            method: `GET`,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
          .then((res) => res.json())
          .then((data) => {
            console.log("Response Data", data);
            this.usersTableData = data;
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    openGateModal(params) {
      this.$modal.show("gate-modal", params);
    },
    openUsersModal() {
      this.$modal.show("users-modal");
    },
    handleModal(configuration, params) {
      switch (configuration) {
        case Constants.GATE: {
          this.openGateModal(params);
          break;
        }
        case Constants.INCIDENTS: {
          console.log("Opening Incidents Modal");
          break;
        }
        case Constants.USERS: {
          console.log("Opening Users Modal");
        }
      }
    },
    setIncidentsData() {
      return new Promise((resolve, reject) => {
        fetch(
          "https://76pn72v42d.execute-api.us-east-2.amazonaws.com/staging-dev/nautica/incidents/home/",
          {
            method: `GET`,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
          .then((res) => res.json())
          .then((data) => {
            console.log("Response Data", data);
            this.incidentsData = data;
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  //this is how we receive the prop
  data() {
    return {
      showKeys: true,
      showIncidents: false,
      users: Constants.USERS,
      gate: Constants.GATE,
      selectedItem: "incidents",
      tableData: [],
      incidentsData: [],
      usersTableData: [],
      alerts: {
        alert_title: "A Sample Alert!",
        alert_message:
          "You now have alerts enabled. Look at all these cool features that you have available in GateWatch. Try clicking on any item in the left navigation to explore more of GateWatch.",
      },
      //{"alert_title":"A Sample Alert!","alert_message":"You now have alerts enabled. Look at all these cool features that you have available in GateWatch. Try clicking on any item in the left navigation to explore more of GateWatch."}
      all_alerts: [
        {
          alert_title: "1 Sample Alert!",
          alert_message:
            "You now have alerts enabled. Look at all these cool features that you have available in GateWatch. Try clicking on any item in the left navigation to explore more of GateWatch.",
        },
        {
          alert_title: "2 Sample Alert!",
          alert_message:
            "You now have alerts enabled. Look at all these cool features that you have available in GateWatch. Try clicking on any item in the left navigation to explore more of GateWatch.",
        },
        {
          alert_title: "3 Sample Alert!",
          alert_message:
            "You now have alerts enabled. Look at all these cool features that you have available in GateWatch. Try clicking on any item in the left navigation to explore more of GateWatch.",
        },
        {
          alert_title: "4 Sample Alert!",
          alert_message:
            "You now have alerts enabled. Look at all these cool features that you have available in GateWatch. Try clicking on any item in the left navigation to explore more of GateWatch.",
        },
      ],
      cardInfo: [
        {
          title: "First Time Keys Created",
          insight_id: "6e390e034cb7c6d64edb431a0e4519e8",
          subtitle_trend: "Good trend",
          sentiment: "positive",
          value: "11%",
          direction: "upward",
        },
        {
          title: "Keys Validated",
          insight_id: "5c779aa30a64c502cf060ef19d8f86a3",
          subtitle_trend: "Warning trend",
          sentiment: "warning",
          value: "3%",
          direction: "downward",
        },
        {
          title: "Average Transaction Time",
          insight_id: "5d9abc085056aac732b96d7fde09cb1a",
          subtitle_trend: "Good trend",
          sentiment: "positive",
          value: "3s",
          direction: "downward",
        },
        {
          title: "Resend Keys Created",
          insight_id: "2f1235b0f19dd2dddace7991d00088c6",
          subtitle_trend: "Great trend",
          sentiment: "positive",
          value: "80%",
          direction: "upward",
        },
        {
          title: "Party Keys Created",
          insight_id: "d1924d54a609019d25762fabd1b7e212",
          subtitle_trend: "Alert trend",
          sentiment: "alert",
          value: "30%",
          direction: "downward",
        },
        {
          title: "Total Keys Expired",
          insight_id: "ef67b32bdf70aaf6a4fb2adc80124288",
          subtitle_trend: "Alert trend",
          sentiment: "alert",
          value: "30%",
          direction: "upward",
        },
      ],
    };
  },
};
</script>

<style scoped>
.currentView {
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  padding: 16px;
  border-radius: 4px;
}
</style>