export default class CtsAuthLibrary {
    constructor() {
        this.AUTH_URL= "https://communitytech.auth.us-east-2.amazoncognito.com"
        this.REDIRECT_URL="'https://dashboard.communitytech.io'"
        this.MANAGEMENT_S3_URL="http://d2mnf69gt2tvvs.cloudfront.net/"
        this.CONFIG_URL=`${this.MANAGEMENT_S3_URL}config/gatewatch/web/config.json`
        this.cookiePrefix="xctsc"
        this.identityTokenCookieIdentifierVal = "i"
        this.refreshTokenCookieIdentifierVal = "r"
        this.applicationCode="gw"
        this.generatedCookieIdentityTokenIdentifier=`${this.cookiePrefix}${this.identityTokenCookieIdentifierVal}${this.applicationCode}`
        this.generatedCookieRefreshTokenIdentifier=`${this.cookiePrefix}${this.refreshTokenCookieIdentifierVal}${this.applicationCode}`
        this.authCodeQueryStringParam="code",
        this.currentAccessToken="",
        this.userData={},
        this.featureFlags={}
    }

    encodeFormData = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
            .join('&');
    }

    retrieveConfig() {
        return new Promise((resolve, reject) => {
            fetch(this.CONFIG_URL,
                {
                    method: `GET`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                }).then(res => res.json())
                .then(data => {
                    console.log("Config Response Data", data)
                    this.featureFlags = data['featureFlags']['resident']
                    console.log("Current Feature Flags ", this.featureFlags)

                    resolve(true)
                }).catch(err => {
                    reject(err)
                })
        });
    }

    generateTokensFromRefreshToken(refreshToken) {
        return new Promise((resolve, reject) => {
            fetch(`${this.AUTH_URL}/token`,
                {
                    method: `POST`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: this.encodeFormData({
                        'grant_type': 'refresh_token',
                        'refresh_token': refreshToken,
                        'client_id': '7aiv43u6m242biboavdfnesrct',
                        'redirect_uri': 'https://dashboard.communitytech.io'
                    })
                }).then(res => res.text())
                .then(data => {
                    console.log("Response Data", data)
                    let authResponse = JSON.parse(data)
                    console.log("Auth Response", authResponse)
                    resolve(authResponse)
                }).catch(err => {
                    reject(err)
                })
        });
    }

    getUserDataFromAccessToken(accessToken) {
        return new Promise((resolve, reject) => {
            fetch(`${this.AUTH_URL}/oauth2/userInfo`,
                {
                    method: `GET`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': `Bearer ${accessToken}`
                    },
                }).then(res => res.text())
                .then(data => {
                    console.log("Response Data", data)
                    let userData = JSON.parse(data)
                    console.log("User Data from Access Token", userData)
                    resolve(userData)
                }).catch(err => {
                    reject(err)
                })
        });
    }

    logout(callback) {
        //Log out
    
        //Clear Cookies for Clean login next attempt
    
        //Execute optional Callback
        if(typeof(callback) == 'function') {
            callback()
        }
    }
    
    
    setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
    
    setIdTokenCookie(cvalue) {
        var d = new Date();
        d.setTime(d.getTime() + (3600 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = `${this.generatedCookieIdentityTokenIdentifier}` + "=" + cvalue + ";" + expires + ";path=/";
    }
    
    setRefreshTokenCookie(cvalue) {
        document.cookie = `${this.generatedCookieRefreshTokenIdentifier}` + "=" + cvalue + ";path=/";
    }
    
    deleteAuthCookies() {
        document.cookie = `${this.generatedCookieIdentityTokenIdentifier}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
        document.cookie = `${this.refreshCookieIdentityTokenIdentifier}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
    
    }
    
    setTokenCookies(authResponse) {
        this.setIdTokenCookie(authResponse['id_token'])
        this.setRefreshTokenCookie(authResponse['refresh_token'])
        this.currentAccessToken = authResponse['access_token']
    }
    
    getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    
    navigateToSignInPage() {
        console.log("Redirecting to Sign In Page")
    }
    
    getTokens(access_code) {
        return new Promise((resolve, reject) => {
            fetch(`${this.AUTH_URL}/token`,
                {
                    method: `POST`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: this.encodeFormData({
                        'grant_type': 'authorization_code',
                        'code': access_code,
                        'client_id': '7aiv43u6m242biboavdfnesrct',
                        'redirect_uri': 'https://dashboard.communitytech.io'
                    })
                }).then(res => res.text())
                .then(data => {
                    console.log("Response Data", data)
                    let authResponse = JSON.parse(data)
                    console.log("Id Token", authResponse['id_token'])
                    console.log("Refresh Token", authResponse['refresh_token'])
                    resolve(authResponse)
                }).catch(err => {
                    reject(err)
                })
        });
    
    }
    
    signOut() {
        this.deleteAuthCookies();
        this.navigateToSignInPage();
    }
    
    apiAuthErrorHandler() {
        let refreshToken = this.getCookie(this.generatedCookieRefreshTokenIdentifier);
        this.generateIdTokenFromRefreshToken(refreshToken).then(idTokenPayload => {
            this.setIdTokenCookie(idTokenPayload['id_token'])
        })
        .catch(err => {
            console.log("Error using refresh token", err)
            this.deleteAuthCookies();
            this.navigateToSignInPage();
        });
    }

    isFeatureEnabled(featureId) {
        return this.featureFlags ? this.featureFlags[featureId] : false
    }
    
    authFlow() {
        return new Promise((resolve, reject) => {
            const queryString = window.location.search;
            console.log(queryString);
            const urlParams = new URLSearchParams(queryString);
            const code = urlParams.get(this.authCodeQueryStringParam)
            console.log("Access Code", code)
            if (code) {
                this.getTokens(code).then(tokens => {
                    if (!tokens['error']) {
                        this.setTokenCookies(tokens);
                        console.log("tokens in Browser", tokens);
                        this.getUserDataFromAccessToken(this.currentAccessToken).then(data => {
                            console.log("User Data from Auth Flow", data)
                            this.userData = data
                            resolve(this.userData)
                        })
                    }
                    else {
                        console.log("Invalid Auth Code Response")
                        this.navigateToSignInPage();
                    }
                })
                .catch (err => {
                    console.log("Error getting tokens", err)
                    reject(err)
                    this.navigateToSignInPage();
                })
            }
            else {
                let idToken = this.getCookie(this.generatedCookieIdentityTokenIdentifier);
                let refreshToken = this.getCookie(this.generatedCookieRefreshTokenIdentifier);
                console.log("refreshToken from cookie", idToken)
                if (!refreshToken) {
                    console.log("No refreshToken found in cookie")
                    if (!refreshToken) {
                        console.log("No Refresh Token found in cookie");
                        this.navigateToSignInPage();
                    }
                }
                else {
                    console.log("Found A Refresh Token")
                    this.generateTokensFromRefreshToken(refreshToken).then(tokenPayload => {
                       this.setIdTokenCookie(tokenPayload['id_token'])
                        this.currentAccessToken=tokenPayload['access_token']
                        this.getUserDataFromAccessToken(this.currentAccessToken).then(data => {
                            console.log("User Data from Auth Flow", data)
                            this.userData = data
                            resolve(this.userData)
                        })
                        
                        
                    });
                }
        
             }
        })
        
    }
    
   

}





